export const carousselItems = [
    {id:0,uri: "/assets/car-0.jpeg",
        desciption: "Avoir des conseils pratiques pour améliorer votre alimentation et retrouver vos repères nutritionnels."},
    {id:1,uri: "/assets/car8.webp",
        desciption: "Déceler  le « vrai » du « faux » des informations nutritionnelles que vous entendez."},
    {id:2,uri: "/assets/car9.jpeg",
        desciption: "Revoir votre façon de cuisiner et recevoir des astuces, des idées et des conseils d’organisation."},
    {id:3,uri: "/assets/img-car-1.png",
        desciption: "Ne pas se sentir tout le temps fatigué(e), irritable ou colérique ! et vous avez envie de retrouver de la vitalité et de rester dans une notion de bien-être mais n'avez pas les outils ?"},
    {id:4,uri: "/assets/img-car-2.png",
        desciption: "Ne pas reprendre les kilos après chaque régime et vous souhaitez retrouver et maintenir votre poids de forme ?"},
    {id:5,uri: "/assets/img-car-3.png",
        desciption: "Avoir plus de confort digestif et être suivi dans le cadre de nouvelles habitudes alimentaires, et profiter de l’aide d’un professionnel."},
    {id:6,uri: "/assets/img-car-4.png",
        desciption: "Bénéficier d’un accompagnement durant votre grossesse ou l'allaitement de votre bébé ?"},
    {id:7,uri: "/assets/img-car-5.png",
        desciption: "Faire un bilan de nutrition-santé dans le cadre d’une démarche préventive  des pathologies cardiovasculaire."},
    {id:8,uri: "/assets/img-car-6.png",
        desciption: "Régler une problématique nutritionnelle en relation avec vos habitudes alimentaires ou une maladie chronique."},
    {id:9,uri: "/assets/img-car-7.png",
        desciption: "Optimiser votre pratique sportive par des conseils nutritionnels (programmes, préparation, menus …) et vous avez besoin d'être accompagné(e) et encouragé(e)."}
]
