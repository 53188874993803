export const Temoignage =[
  {
    id:0,
    user:'Mehdi Menacer',
    img:'/assets/user-solid.svg',
    comment:'Bonjour , j’ai terminé mon expérience de 3 mois avec NutriCT , j’ai eu un grand changement concernant mon corps et ma nutrition et ma facon de vivre, c’était super bien avec Le Dc Taleb et son coaching .',
    rating:'4'
  },
  {
    id:1,
    user:'Nacim merahba',
    img:'/assets/user-solid.svg',
    comment:'Vous êtes suivi par un proffessionnel de la nutrition Certifiè et diplomé, spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.',
    rating:'3'
  },
  {
    id:2,
    user:'User 3',
    img:'/assets/user-solid.svg',
    comment:'Vous êtes suivi par un proffessionnel de la nutrition Certifiè et diplomé, spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.',
    rating:'5'
  },
  {
    id:3,
    user:'User 3',
    img:'/assets/user-solid.svg',
    comment:'Vous êtes suivi par un proffessionnel de la nutrition Certifiè et diplomé, spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.',
    rating:'5'
  },
  {
    id:4,
    user:'User 3',
    img:'/assets/user-solid.svg',
    comment:'Vous êtes suivi par un proffessionnel de la nutrition Certifiè et diplomé, spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.',
    rating:'5'
  }
]
