<template>
  <div >
    <div class="flex justify-center items-start my-2">
        <div class="w-full my-1">
          <ul class="flex flex-col">
            <li class="bg-white my-2 shadow-lg">
              <div
                class="flex flex-row justify-between items-center p-3 cursor-pointer"
                style="min-height: 88px;"
              >
                <div class="flex flex-row items-center">
                  <img :src='assetPath+"/assets/intero.svg"' class="mr-2" style="width: 30px; height: auto;">
                  <span class="faq-quest">{{ question }}</span>
                </div>
                <img style="width: 26px; height: auto;" @click="showAnswer = !showAnswer" :src='assetPath+"/assets/downArrow-green.svg"'  :class="{'rotate-180 delay-150 duration-700 ':showAnswer,'delay-150 duration-700':!showAnswer}">
              </div>
              <div :style="showAnswer ? 'max-height: 1800px !important; overflow: visible !important; padding:20px' : ''"
                class="border-l-2 border-green-700 overflow-hidden max-h-0 duration-500 transition-all"
              >
                <p class="p-3 faq-answer duration-500 transition-all">{{ answer }}</p>
              </div>
            </li>
          </ul>
        </div>
    </div>
  </div>

</template>
<script>
export default {
  data(){
    return{
      showAnswer:false,
    }
  },
  props:{
    question: String,
    answer: String
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
  mounted() {

  }
}
</script>
<style scoped>
@import '../../../sass/components/about.css';
</style>
