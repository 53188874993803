<template>
  <div class="main-body">
    <div class="w-full flex flex-row content-center items-center justify-between flex-wrap max-sm:justify-center">
      <h1 class="font-extrabold text-green-700 text-4xl max-sm:mb-2">Infos Nutrition</h1>
      <div class="relative flex items-center">
        <img class="absolute right-4" :src='assetPath+"/assets/search.svg"' />
        <input type="text" placeholder="Rechercher..." v-model="search" class="cstm-input focus:ring-0"/>
      </div>
    </div>
      <div class="w-full mt-6 mb-8">
        <div class="flex justify-center items-center">
          <div class="flex flex-row flex-wrap items-start max-sm:justify-center">
            <div v-for="blog in filteredBlogs" :key="blog.id" class="flex flex-col items-center p-5 pl-0 mb-6" style="width: 290px;height: 465px;">
              <img :src="assetPath + blog.img" class="rounded-xl " style="height: 200px; width: 255px;"/>
              <p class="description-title">{{blog.title}}</p>
              <p class="date">{{ blog.posted_at }}</p>
              <div style="width: 250px; ">
                <p class="text">{{ miniatureText(blog.txt) }}</p>
              </div>
              <router-link :to="`article/${blog.id}`" @click="scrollToTop">
                <button type="submit" class="submit-btn">
                  Lire la suite
                </button>
              </router-link>
            </div>
          </div>
        </div >
      </div>
  </div>
</template>
<script>

import {Blogs} from"../constants/Blogs"

export default {
  name: "Nutrition",


  data() {
    return {
      step: 1,
      search:'',
      Blogs,
    }
  },
  computed:{
    filteredBlogs() {
      return this.Blogs.filter(blog => {
        return blog.title.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
  methods:{
    miniatureText(text){
      if (text.length > 193){
        return text.substr(0,193)+"...";
      }
      return text;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },

  }
}
</script>

<style scoped>
  @import '../../../sass/components/nutrition.css';
</style>
