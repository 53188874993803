export const  profils = [
    {
        name: "Minceur santé",
        description: [
            'Perte de poids;',
            'Silhoutte harmonieuse;',
            'Approche personnalisée et holistique;',
            'Objectifs réalistes et durables.',
        ],
        uri: "/assets/body.svg"
    },
    {
        name: "Rééquilibrage Alimentaire et bien être",
        description: [
            'Alimentation équilibrée et diversifiée;',
            'Rééducation du comportement alimentaire;',
            'Le contrôle des compulsions alimentaires;',
            'Amélioration du bien être physique et moral.'
        ],
        uri: "/assets/dish.svg"
    },
    {
        name: "Maladie Chronique ",
        description: [
            'Diabète, hypertension artérielle, arhtrose...',
            'Accompagnement personnalisé;',
            'Amélioration de la qualité de vie.',
        ],
        uri: "/assets/health-care.svg"
        },
    {
        name: "Sportif",
        description: [
            'Plan nutritionnel adapté à la discipline sportive;',
            'Gain de masse musculaire;',
            'Amélioration d’endurance physique;',
            'Optimisation des performances.'
        ],
        uri: "/assets/sport.svg"
    },
    {
        name: "Femme Enceinte/ allaitante",
        description: [
            'Nutrition optimal : Grossesse et Allaitement;',
            'Croissance harmonieuse du foetus;',
            'Renforcement du lien Mère-Enfant;',
            'Equilibre physique et émotionnel maternel.'
        ],
        uri: "/assets/pregnant.svg"
    },
    {
        name: "Femme préménopausée / Ménopausée",
        description: [
            'Choix nutrionnels adéquats;',
            'Prévention de l’ostéoporose;',
            'Lutter contre les bouffées de chaleur;',
            'Optimisation de la qualité de vie.'
        ],
        uri: "/assets/women.svg",
    },
    {
        name: "Séniors",
        description: [
            'Amélioration du capital santé;',
            'Plan alimentaire personnalisé;',
            'Accompagnement basé sur les 5 facteurs du vivant;',
            'Optmisation de la faculté mentale.'
        ],
        uri: "/assets/old_woman.svg"
    },
    {
        name: "Enfant/Adolescent ",
        description: [
            'Approche nutritionnelle contre l’hyperactivité; ',
            'Optimisation de phase de croissance;',
            'Équilibre psychologique soutenue;',
            'Solution des problématiques parentales.'
        ],
        uri: "/assets/teenager.svg"
    },
];
