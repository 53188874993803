<template>
  <div class="container-caroussel" :style="{ backgroundImage: 'url(' + assetPath + '/assets/caroussel-bg.png)' }">
    <swiper
        ref="carousselItems"
        :effect="'coverflow'"
        :grabCursor="false"
        :centeredSlides="true"
        :slidesPerView="3"
        :spaceBetween="110"
        :loop="true"
        :breakpoints="{
          '320': {slidesPerView: 1,spaceBetween: 20,},
          '516': {slidesPerView: 2,spaceBetween: 20,},
          '768': {slidesPerView: 3,spaceBetween: 20,},
        }"
        :coverflowEffect="{
          rotate: 0,
          stretch: -50,
          depth: 100,
          modifier: 3,
          slideShadows: false,
        }"
        :pagination="{
          el:'.swiper-pagination',
          clickable: true
        }"
        :navigation="true"
        :modules="modules"
        class="mySwiper"
        style="height: 600px;"
    >
      <swiper-slide v-for="carousselItem in carousselItems"
                    :key="carousselItem.id" class="swiper-slide"  v-slot="{ isActive }" >
        <img :src="assetPath+carousselItem.uri" :class="isActive ? 'img-active' : 'img' "/>
        <transition enter-from-class="opacity-0"
                    leave-to-class="opacity-0"
                    enter-active-class="transition duration-500"
                    leave-active-class="transition duration-400">
        <div v-show="isActive" class="bg-white mt-2 text-center shadow-lg shadow-green-700/50 px-2 mb-1">
          <p class="slider-text">{{carousselItem.desciption}}</p>
        </div>
          </transition>
      </swiper-slide>
    </swiper>
  </div>

</template>
<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {carousselItems} from "../constants/CarousselItems";

import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {EffectCoverflow, Pagination, Navigation} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {

    return {
      modules: [Navigation,EffectCoverflow, Pagination ],
    };
  },
  data () {
    return {
      carousselItems,
    };
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    }
  }
};

</script>

<style>


.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  transition:all 200ms linear;
  transform: scale(0.8);

}
.img-active {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  transition:all 200ms linear;
  transform: scale(1);
  display: block;
  width: 100%;
  border-bottom: 20px solid green !important;
  border-right: 20px solid green !important;
}

.img {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  transition:all 200ms linear;
  transform: scale(0.8);
  display: block;
  width: 100%;
  border-radius: 30px;
  opacity: 60%;

}
.slider-text {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  text-indent: 6px;
  color: #695E37;
}

.container-caroussel {
  width: 100%;
  height: 450px;
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}
.swiper-button-prev {
  color: green;
}

.swiper-button-next {
  color: green;

}
:root {
  --swiper-navigation-size: 75px;
}

</style>
