import {createRouter,createWebHistory} from "vue-router";
import Home from "../views/Home.vue";
import About from "../components/About.vue";
import ContactUs from "../components/ContactUs.vue";
import LoginPortal from "../components/LoginPortal.vue";
import LogIn from"../components/LogIn.vue";
import Payment from "../components/Payment.vue";
import Seance from "../components/Seance.vue";
import Faq from "../components/Faq.vue";
import Nutrition from "../components/Nutrition.vue";
import Article from "../components/Article.vue";
const routes = [
    {
        path:'/',
        name:'home',
        component:Home
    },

    {
        path: "/about",
        component: About
    },
    {
        path:"/contact-us",
        component: ContactUs
    },
    {
        path:"/login-portal",
        component: LoginPortal,
    },
    {
        path:"/login",
        component: LogIn
    },
    {
        path:"/consultation",
        component: Payment
    },
    {
        path:"/seance",
        component:Seance
    },
    {
        path:"/faq",
        component:Faq
    },
    {
      path:"/nutrition",
      component: Nutrition
    },
    {
      path:"/article/:id",
      component: Article
    }
];

const router=createRouter({
    history: createWebHistory(),
    routes
})
export default router;
