<template>
    <div class="banner mb-24 mt-14" :style="{ backgroundImage: 'url(' + assetPath + '/assets/banner.png)' }" >
      <div class="h-full flex flex-col justify-between py-16 px-24">
        <div>
          <p class="font-bold sm:text-6xl max-sm:text-center text-xl text-black mb-3">Consultation en <br>Nutrition <span style="color: #238C56" >Santé et<br> Bien-être </span> <br>En ligne </p>
        </div>
        <div class="flex justify-center max-md:flex-col">
          <p class="font-bold text-md text-black md:relative max-md:text-center z-0" style="right: 180px; top: 10px; font-size: 26px">Réservez votre Rendez-vous <br> Découverte gratuite !</p>
            <button class="orange-btn hover:bg-orange-600 md:relative z-0" style="top: 35px; right:97px"><a href='https://appointeo.com/service/3//nutrict' target="_blank">  Réservez Gratuitement  </a></button>
        </div>
      </div>
    </div>
    <div class="w-full">
      <div class="flex flex-row items-center justify-center mb-5">
        <p class="font-bold text-3xl mt-8 px-4 text-center">Le coaching en <span class=" text-green-700">Nutrition-santé </span>s’adresse à vous si vous souhaitez:</p>
      </div>
      <NutriCaroussel/>
      <div class="max-sm:hidden flex flex-col items-center mt-20 w-full">
        <p class="plus-title">Les Plus de <span style="color: #238C56;">NutriCT</span></p>
        <div class="mt-44 mb-64 green-star lg:scale-100 md:scale-75 sm:scale-50">
          <div id="circle-green" class="green-circle flex items-center justify-center flex-col">
            <img id="logo-white" class="nct-logo" style="position: relative; top: 560px;" :src='assetPath+"/assets/nct-white-logo.svg"'>
              <div style="position: relative;top: 180px; right: 350px;">
                <hr class="arrow" style="top: 159px; left: 216px; transform: rotate(30deg); width: 204px;">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <img class="header-icon" :src='assetPath+"/assets/suivi.svg"'>
                    <p class="card-title pl-4">Suivi Professional</p>
                  </div>
                  <p class="card-text">Vous êtes suivi par un professionnel de la nutrition Certifié et diplômé,  spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.</p>
                </div>
              </div>
              <div style="position: relative; top: 230px; right: 440px;">
                <hr class="arrow" style="top: 80px; left: 320px; width: 120px;">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <img class="header-icon" :src='assetPath+"/assets/schedule.svg"'>
                    <p class="card-title pl-1">Disponibilité</p>
                  </div>
                  <p class="card-text pt-3">En ligne et même en dehors des consultations: contact par mail, téléphone, réseaux sociaux. </p>
                </div>
              </div>
              <div style="position: relative;top: 280px; right: 350px;">
                <hr class="arrow" style="top: 50px; left: 300px; width: 120px; transform: rotate(-35deg);">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <img class="header-icon" :src='assetPath+"/assets/advice.svg"'>
                    <p class="card-title pl-1">Conseils Personnalisés</p>
                  </div>
                  <p class="card-text pt-5">Des conseils personnalisés et adaptés à chacun, à son mode de vie et à ses convictions.</p>
                </div>
              </div>
              <div style="position: relative; top: 280px; right: 0px;">
                <hr class="arrow" style="bottom: 50px; left: 100px; width: 120px; transform: rotate(90deg);">
                <div class="information-card p-5">
                  <div class="flex flex-col items-center justify-center" style="margin-top: -40px;">
                    <img style="width: 52px; height: 52px;" :src='assetPath+"/assets/engagement.svg"'>
                    <p class="card-title text-center pl-1 pt-3">Aucun engagement obligatoire</p>
                  </div>
                </div>
              </div>
              <div style="position: relative;top: -20px; right: -350px;">
                <hr class="arrow" style="top: 30px; right: 126px; width: 154px; transform: rotate(35deg);">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <p class="card-title pr-5">Pas de régime restrictif</p>
                    <img class="header-icon-r" :src='assetPath+"/assets/diet.svg"'>
                  </div>
                  <p class="card-text pt-3">Une approche plus souple avec une éducation alimentaire basée sur l’assiette santé, les habitudes alimentaires et une bonne hygiène de vie.</p>
                </div>
              </div>
              <div style="position: relative;top: -409px; left: 420px;">
                <hr class="arrow" style="top: 80px; right: 126px; width: 154px; ">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <p class="card-title pl-1">Accompagnement <br> vers la pleine santé</p>
                    <img class="header-icon-r" :src='assetPath+"/assets/health.svg"'>
                  </div>
                  <p class="card-text pt-2">Une démarche qui inscrit votre objectif dans le cadre des cinq facteurs du vivant pour garantir un état de bien être général.</p>
                </div>
              </div>
              <div style="position: relative; bottom: 780px; left: 350px;">
                <hr class="arrow" style="top: 170px; right: 122px; width: 154px; transform: rotate(-30deg);">
                <div class="information-card p-5">
                  <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                    <p class="card-title pl-1">Plaisir et convivialité</p>
                    <img class="header-icon-r" :src='assetPath+"/assets/people.svg"'>
                  </div>
                  <p class="card-text pt-3">Une démarche nutritionnelle qui associe le plaisir et la convivialité est essentielle pour profiter d'une vie équilibrée et agréable.</p>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="sm:hidden flex flex-col items-center mt-6 w-full">
        <p class="plus-title">Les Plus de <span style="color: #238C56;">NutriCT</span></p>
        <div class="flex flex-row justify-center items-center mt-5">
          <div class="green-vr"></div>
          <div class="mt-5">
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/suivi.svg"'>
                  <p class="card-title pr-4">Suivi Professional</p>
                </div>
                <p class="card-text text-start">Vous êtes suivi par un professionnel de la nutrition Certifié et diplômé,  spécialisé en éducation alimentaire et comportementale, en rééquilibrage alimentaire et micronutrition du sport.</p>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/schedule.svg"'>
                  <p class="card-title">Disponibilité</p>
                </div>
                <p class="card-text text-start">En ligne et même en dehors des consultations: contact par mail, téléphone, réseaux sociaux.</p>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/advice.svg"'>
                  <p class="card-title pr-6">Conseils Personnalisés</p>
                </div>
                <p class="card-text text-start">Des conseils personnalisés et adaptés à chacun, à son mode de vie et à ses convictions.</p>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-col items-center justify-center" style="margin-top: -40px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/engagement.svg"'>
                  <p class="card-title text-center pl-1 pt-3 mt-14">Aucun engagement obligatoire</p>
                </div>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/diet.svg"'>
                  <p class="card-title pr-6" style="font-size: 20px;">Pas de régime restrictif</p>
                </div>
                <p class="card-text text-start">Une approche plus souple avec une éducation alimentaire basée sur l’assiette santé, les habitudes alimentaires et une bonne hygiène de vie.</p>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/health.svg"'>
                  <p class="card-title pr-6" style="line-height: 24px;">Accompagnement <br> vers la pleine santé</p>
                </div>
                <p class="card-text text-start">Une démarche qui inscrit votre  objectif dans le cadre des cinq facteurs du vivant pour garantir un état de bien être général. </p>
              </div>
            </div>
            <div class="flex flex-row items-center mb-10">
              <hr class="arrow" style="width: 10%;">
              <div class="information-card p-5">
                <div class="flex flex-row items-end justify-center" style="margin-top: -15px;">
                  <img class="header-icon-mobile" :src='assetPath+"/assets/people.svg"'>
                  <p class="card-title pr-6">Plaisir et convivialité</p>
                </div>
                <p class="card-text text-start">Une démarche nutritionnelle qui associe le plaisir et la convivialité est essentielle pour profiter d’une vie équilibrée et agréable.</p>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="w-full mb-28 flex flex-col items-center">
        <div class="flex flex-row items-center justify-center mb-5">
          <p class="font-bold text-3xl mt-8 px-4 text-center"> <span class=" text-green-700">NutriCT</span> assure l’accompagnement des profils: </p>
        </div>
        <div class="flex flex-row flex-wrap justify-center mt-16">
          <div v-for="(profil,i) in  profils " :key="i" class="mt-3 mb-12" >
            <div class="profil-card m-8">
              <div class="circle-logo">
                <img class="self-center" :src="assetPath+profil.uri"/>
              </div>
              <p class="description-title">{{ profil.name }}</p>
              <ul class="px-1 pt-5 scrollbar card-info mr-1">
                <li v-for="(description, e) in profil.description" :key="e" class="profil-details">
                  <div class="flex flex-row">
                    <img :src='assetPath+"/assets/nct-leaf.svg"'>
                    <p class="description">{{description}}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="text-center font-bold text-2xl mt-8 w-8/12"> <span class=" text-green-700">NutriCT</span> vous garantit un travail d’accompagnement unique, de qualité et
         totalement personnalisé en fonction de vos besoins. </p>
        <!--<div class="w-full flex flex-col mt-20">
          <p class="plus-title text-center">Ce que les ges disent sur <span style="color: #238C56;">NutriCT</span></p>
        </div>
        <div class="w-full items-center mt-8">
          <TemoignageCaroussel/>
        </div>-->
      </div>
  </div>
</template>

<script setup>
import {profils} from "../constants/Profils"
import NutriCaroussel from "../components/NutriCtCaroussel.vue";
import TemoignageCaroussel from "../components/TemoignageCaroussel.vue";
</script>
<script>
export default {
  data(){
    return{
      showAnswer:false,
    }
  },
  props:{
    question: String,
    answer: String
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  }
}
</script>

<style scoped>
@import '../../../sass/components/home.css';
</style>
