<script setup>
</script>
<template>
  <div class="main-body p-12 max-sm:px-5">
    <div class="div-style flex items-center flex-row flex-wrap justify-around p-5 max-sm:px-5">
      <div class="first-div w-4/12 max-md:w-full">
        <img :src='assetPath+"/assets/nct-white-logo.svg"' class="white-logo mb-5 max-sm:mb-0"/>
        <p class="logo-text mb-10">
          Pour toute demande ou interrogatoire appelez nous  prennez contact via le formulaire suivant
        </p>
        <img :src='assetPath+"/assets/ContactUsWomen.png"' class="image white-logo mb-5"/>
      </div>
      <div class="second-div">
        <p class="contact-text">Contactez Nous</p>
          <form @submit.prevent="submitForm">
            <div class="form-div">
              <div class="form-row mb-5">
                <div class="form-col pl-2">
                  <p class="form-text">Prénom</p>
                  <input id="name" v-model="fname" required class="text-field">
                </div>
                <div class="form-col pr-2">
                  <p class="form-text">Nom</p>
                  <input id="name" v-model="lname" required class="text-field">
                </div>
              </div>
                <p class="form-text">Email</p>
                <input type="email" id="email" v-model="email" required class="text-field mb-5">
                <p class="form-text">Objet de votre demande</p>
                <input type="text" id="demande" v-model="object" required class="text-field mb-5">
                <p class="form-text">Message</p>
                <textarea id="message" v-model="message" required class="message-field mb-5"> </textarea>
              <div class="flex justify-center">
              <button type="submit" class="submit-style">
                Envoyer
              </button>
              </div>
            </div>
          </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      fname: '',
      lname: '',
      email: '',
      object: '',
      message: ''
    };
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('/contact', {
          lname: this.lname,
          fname: this.fname,
          email: this.email,
          object : this.object,
          message: this.message
        });
          alert('Message sent successfully!');
          this.lname = '';
          this.fname = '';
          this.email = '';
          this.object = '';
          this.message = '';
      } catch (error) {
        console.error(error);
        alert('Failed to send message!');
      }
    }
  }
};
</script>
<style scoped>
.main-body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.div-style {
  background-color: #238C56;
  border-radius: 20px;
}

.white-logo {
  width: 350px;
  min-width: 250px;
  height: 150px;
}

.logo-text {
  color: white;
  font-family: 'Rokkitt', serif;
  font-weight: 700;
  font-size: 20px;
}

.contact-text {
  color: white;
  font-family: 'Rokkitt', serif;
  font-weight: 700;
  font-size: 40px;
}

.first-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.second-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.form-div{
  background-color: white;
  border-radius: 20px;
  text-align: left;
  padding: 30px;
}

.image {
  width: 90%;
  height: auto;
}

.text-field{
  width: 100%;
  height: 36px;
  padding: 0px 5px;
  border: 1px solid #A2D5AC;
  border-radius: 10px;
}

.message-field{
  width: 100%;
  height: 130px;
  border-radius: 5px;
  border: 1px solid #A2D5AC;
}

.form-text{
  font-family: 'Rokkitt', serif;
  font-weight: 700;
  font-size: 20px;
  color:#808080;

}

.form-row{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

}

.form-col{
  display: flex;
  flex-direction: column;
  width: 50%;
}

.submit-style{
  max-width: 190px;
  width: 100%;
  height: 36px;
  border-radius: 8px;
  background-color: #238C56;
  font-family: 'Rokkitt', serif;
  color: white;
  text-align: center;
}

</style>
