<template>
  <div>
    <div class="main-body">
      <div class="w-full flex flex-row items-center justify-end flex-wrap max-sm:justify-center">
<!--        <div class="relative flex items-center">
          <img class="absolute right-4" :src='assetPath +"/assets/search.svg"' />
          <input type="text" placeholder="Rechercher..." v-model="search" class="cstm-input focus:ring-0"/>
        </div>-->
      </div>
    </div>
    <div class="article-banner flex items-center justify-center my-6" :style="{ backgroundImage: 'url(' + assetPath + '/assets/article-1-banner.png)' }">
      <span class="article-title">{{findArticle.title}}</span>
    </div>
    <div class="main-body max-sm:px-5">
      <div class="col-12 mt-12">
        <div>
          <p class="text text-center">{{findArticle.introduction[0]}}</p>
        </div>
      </div>
      <br/>
      <div class="col-12">
        <div>
          <p class="text text-center">{{findArticle.introduction[1]}}</p>
        </div>
      </div>
      <div class="col-12 mt-12">
        <div v-for="(p_title,key) in findArticle.p_titles">
          <p class="font-bold text-3xl mt-8 px-4">{{p_title}}</p>
          <p class="text" style="white-space: pre-line">
            {{findArticle.paragraphs[key]}}
          </p>
        </div>
      </div>
      <div class="col-12 mt-12">
        <div>
          <p class="font-bold text-3xl mt-8 px-4">Bibiographie:</p>
          <div  v-for="(reference,key) in findArticle.references"  >
            <p class="text" style="white-space: pre-line">
              - {{reference}}
            </p>
            <a style="color: #0d7aff" :href="findArticle.links[key]">{{findArticle.links[key]}}</a>
          </div>
        </div>
      </div>

      <div class="flex flex-row items-start w-full mt-12">
          <div class="flex flex-col justify-start">
            <div class="flex flex-row items-start">
              <p class="author">{{ findArticle.author }}</p>
              <img :src="assetPath +'/assets/nct-leaf.svg'" class="ml-2"/>
            </div>
            <p class="date mt-2">{{ findArticle.posted_at }}</p>
            <div class="mt-2">
              <p class="font-bold">TAGS</p>
              <div class="flex flex-row flex-wrap">
                <div v-for="item in findArticle.tags" class="tag-box mr-2 mb-2">
                  <p class="mx-2 font-bold">{{ item }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr class="line-grey mt-12">
        <div class="w-full flex flex-col mb-8">
          <p class="title-section mt-3 mb-3" style="background: none;">LAISSER UN COMMENTAIRE</p>
          <div class="w-full">
            <div class="mb-8">
              <div class="form-div flex flex-col justify-center max-sm:pl-0">
                <form @submit.prevent="submitForm">
                  <div class="flex flex-col mb-4">
                    <p class="label-text">Prénom</p>
                    <input id="name" class="text-field w-4/12 max-lg:w-6/12 max-sm:w-full" v-model="fname" type="text">
                    <p class="label-text">Nom</p>
                    <input id="name" class="text-field w-4/12 max-lg:w-6/12 max-sm:w-full" v-model="lname" type="text">
                    <p class="label-text">Email</p>
                    <input type="email" id="email" v-model="email"  class="text-field w-4/12 max-lg:w-6/12 max-sm:w-full">
                  </div>
                  <p class="label-text">Votre Commentaire</p>
                  <textarea id="message" v-model="message" required class="message-field max-sm:w-full"></textarea>
                  <div class="isi">
                    <button type="submit" class="submit-style">
                      <p class="submit-text">Soumettre</p>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <hr class="line-grey">
<!--        <div class="w-full flex flex-col">
          <p class="title-section mt-3 mb-3" style="background: none;">LES COMMENTAIRES</p>
          <div class="flex w-full flex-col mb-8 items-center">
            <div v-for="item in findArticle.comments" class="flex flex-row w-3/4">
              <div class="flex flex-col w-full">
                <p class="user-name">{{ item.author }}</p>
                <p class="date">{{ item.posted_at }}</p>
                <p class="comment">{{ item.comment}}</p>
                <hr class="line-grey my-6">
              </div>
              <div class="flex items-center">
                <a class="text-green-700 font-bold ml-4">Répondre</a>
              </div>
            </div>
          </div>
        </div>-->
    </div>
    <!-- caroussel-->
    <!-- <div class="flex flex-col justify-center items-center mb-12">
      <hr class="line-grey">
      <h1 class="font-extrabold text-green-700 text-2xl text-center mt-3 mb-3">VOUS  POURIEZ AUSSI AIMER </h1>
      <div class=" w-full">
        <div class="flex flex-row  mb-8">
          <swiper
            ref="Blogs"
            :grabCursor="false"
            :centeredSlides="false"
            :slidesPerView="4"
            :spaceBetween="1"
            :loop="false"
            :navigation="true"
            :modules="modules"
            :breakpoints="swiperOptions.breakpoints"
            style="width: 100%;"
            class=""
          >

            <swiper-slide v-for="blog in Blogs"
                          :key="blog.id"   >
              <router-link :to="`${blog.id}`" class="">
              <img :src="blog.img" class="image"/>
              <transition enter-from-class="opacity-0"
                          leave-to-class="opacity-0"
                          enter-active-class="transition duration-500"
                          leave-active-class="transition duration-400">
                <div  class="slide-description">
                  <p class="description-title">{{blog.title}}</p>
                  <p class="date text-center">{{blog.posted_at}}</p>
                </div>
              </transition>
              </router-link>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
import {Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/vue";
import {Blogs} from "../constants/Blogs";
import axios from "axios";
export default {
  name: "Article",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return{
      step:1,
      Blogs,
      swiperOptions:{
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          742: {
            slidesPerView: 3,
            spaceBetween: 10
          },
          // when window width is >= 480px
          992: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          // when window width is >= 640px
          1440: {
            slidesPerView: 5,
            spaceBetween: 30
          }
        }
      },
      fname: '',
      lname: '',
      email: '',
      object: '',
      message: ''
    }
  },
  computed:{
    articleID(){
      return parseInt(this.$route.params.id)
    },
    findArticle(){
      return Blogs.find(findArticle => findArticle.id === this.articleID)
    },
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
  methods: {
    async submitForm() {
      try {
        const response = await axios.post('/contact', {
          lname: this.lname,
          fname: this.fname,
          email: this.email,
          object : 'commentaire: '+ this.findArticle.title,
          message: this.message
        });
        alert('Commentaire ajouté!');
        this.lname = '';
        this.fname = '';
        this.email = '';
        this.object = '';
        this.message = '';
      } catch (error) {
        console.error(error);
        alert('Failed to send message!');
      }
    }
  }
}
</script>
<style>
  @import '../../../sass/components/article.css';
</style>
