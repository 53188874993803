<script setup>

</script>
<template>
  <div class="main-div w-full flex flex-row flex-wrap justify-evenly p-28">
    <div class="flex flex-col items-start w-2/6 pl-12">
      <img src="/public/assets/nct-green-logo.svg" class="logo-green">
      <a href="#" class="text-connect pt-16 pb-12">Se connecter </a>
      <div class="w-full" style="position: relative; right: 30px;">
        <div class="flex flex-row w-full">
          <img src="/public/assets/email-green.svg" class="green-icon"/>
          <input class="costom-input" type="email">
        </div>
        <div class="flex flex-row mt-5">
          <img src="/public/assets/password-green.svg" class="green-icon"/>
          <input class="costom-input" type="password">
        </div>
      </div>
      <a href="#" class="green-text mt-2">Mot de pass oublier ?</a>
      <div class="flex flex-row items-center mt-2">
        <input type="checkbox" class="costom-box">
        <p class="grey-text pl-2">Restez connecter </p>
      </div>
    </div>
    <div>
      <img src="/public/assets/log-picture.png" class="image hidden xl:block">
    </div>
  </div>
</template>
<style scoped>
  @import '../../../sass/components/login.css';
</style>
