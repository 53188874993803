export const Blogs = [
  {
    id:0,
    title:'Cuisiner soi-même : une habitude à adapter pour une vie et une planète plus saine.',
    author:'Dr Zineb Taleb',
    posted_at:'12 Juin 2023',
    img:'/assets/article1.jpeg',
    txt:'Le choix entre cuisiner ses repas soi-même et l\'achat de plats préparés prêts à manger, un débat au centre de la motivation des consommateurs pour gagner le contrôle de leurs assiettes....',
    tags:['Cuisine', 'Santé', 'Écologie' , 'Gastronomie', 'Convivialité'],
    introduction: ['Le choix entre cuisiner ses repas soi-même et l\'achat de plats préparés prêts à manger, un débat au centre de la motivation des consommateurs pour gagner le contrôle de leurs assiettes.\n',
      'La cuisine faite maison offre des avantages en termes de qualité des ingrédients, de personnalisation, d\'expérience culinaire, et d\'économie à long terme, tandis que l\'achat de plats prêts à manger peut être pratique et rapide, mais peut avoir des limites en termes de qualité nutritionnelle, voire des effets néfastes sur la santé à long terme, Les repas industriels peuvent être riches en sel, en sucre, en graisses saturées et en additifs alimentaires, ce qui peut entraîner des problèmes de santé tels que l\'obésité, les maladies cardio-métabolique.\n'],
    p_titles: [
      "Qu'est-ce qu'un fait maison ?",
      "Comment cela peut-il être avantageux ? et dans quel sens ?\n",
      "Des avantages financiers  …. C’est bon aussi pour votre portefeuille !\n",
      "Cuisiner… une expérience gustative personnalisée",
      "Cuisiner soi-même… une démarche écologique!",
      "Un patrimoine gastronomique à préserver...",
      "Le fait maison, une activité neurosensorielle bénéfique",
      "La cuisine favorise la convivialité et renforcer le lien social\n"
    ],
    paragraphs: [
      "Le fait-maison est un mode alimentaire qui consiste à préparer soi-même ses repas au lieu d’acheter des plats préparés. Cette pratique culinaire englobe plusieurs tâches : planifier un menu alimentaire, faire les courses, choisir les ingrédients et les recettes, décorer les plats… tout un art quoi !\n" +
      "De plus en plus de personnes adaptent cette activité plus ou moins régulièrement qui s'avère être très avantageuse tant pour leur santé que pour leur portefeuille. \n" +
      "Dans cet article, nous allons explorer les multiples vertus du fait maison sur la santé, l'environnement, l'économie personnelle et la satisfaction personnelle.\n",
      "Des avantages sanitaires… Une habitude à adopter pour une vie saine\n" +
      "Cuisiner soi-même permet en effet de contrôler les ingrédients utilisés dans les recettes, de les adapter à votre profil alimentaire, de mieux choisir les légumes et les fruits de saison, les moins chimiquement traités. D'éviter les additifs et les conservateurs et surtout de limiter les produits ultra-transformés. \n" +
      "Préparer ses plats donne la possibilité de cuisiner selon ses besoins quantitatif et qualitatif, cela permet d’ajuster la quantité de sel, le choix et la quantité des matières grasse et de sucre.  Afin d’obtenir un repas complet avec la valeur nutritionnelle souhaitée.\n" +
      "\n" +
      "Une étude publiée en 2017 dans la revue internationale de nutrition comportementale et d'activité physique (1) consiste à chercher à évaluer si la fréquence de consommation de repas fait maison était transversalement associée à la qualité nutritionnelle et à la santé cardio-métabolique.\n" +
      "Les résultats affirment que la consommation plus fréquente de repas cuisinés à la maison était associée à une meilleure qualité nutritionnelle et à une très faible adiposité, ce qui limite les maladies cardiovasculaires et métaboliques telles que l'obésité.\n",
      "C’est clair qu’un plat fait maison coûtera moins cher qu’un plat acheté. Cela peut revenir à plusieurs paramètres : en faisant les courses, les ingrédients frais achetés en vrac ou en promotion peuvent être beaucoup moins chers que les plats préparés. De plus, en cuisinant soi-même, on peut préparer des quantités adaptées à ses besoins, évitant ainsi le gaspillage alimentaire et en réduisant les dépenses inutiles. \n" +
      "\n" +
      "Selon une étude Ipsos* (2), les Français réalisent des économies grâce au ‘do it yourself’, une tendance qui devient un réel phénomène de société concernant une large palette d’activités, avec en premier lieu la cuisine à partir de produits bruts non transformés (88%). Ils estiment en moyenne des économies de 272 euros réalisées sur l’année, soit un peu plus de 20 euros par mois.\n" +
      "Une autre étude plus récente publiée par L’INRA (3) visant à comparer le coût des plats fait maison et l’achat des plats industriels, les résultats de cette étude publiée dans Public health nutrition de mai 2020 affirment que pour quatre portions, les plats industriels sont en moyenne 0,84 euro plus chers que les plats faits maisons. \n",
      "En cuisinant chez soi, on opte pour des ingrédients frais et de qualité, des fruits et des légumes locaux et de saison, ce qui améliore la qualité gustative des plats. Les aliments préparés industriellement contiennent souvent des conservateurs qui peuvent altérer le goût et la qualité des ingrédients.\n" +
      "En plus de gérer son alimentation, cette pratique culinaire vous donne la capacité d’adapter les plats et de jouer avec les saveurs selon vos goûts et vos préférences alimentaires. À travers les épices, les herbes, les aromates, les condiments, vous pouvez créer vos propres mélanges qui donnent aux plats une saveur unique et personnelle parfaite à vos envies gustatives.\n" +
      "Avec la pratique, vous deviendrez un expert(e) culinaire, vous maitrisez encore mieux des techniques de cuisson spécifique ce qui peut améliorer la texture et la saveur des plats.\n" +
      "Alors êtes-vous prêts à vous lancer dans ce défi de plaisirs culinaires ?\n" +
      "\n",
      "Cuisiner soi-même est plus qu’une habitude ou un style de vie, mais une démarche écologique en faveur de notre planète, elle est plus respectueuse de l’environnement, car elle permet de limiter les emballages et les déchets produits par l'industrie agroalimentaire. De choisir les fruits et les légumes locaux et de saison, réduisant ainsi les émissions de gaz à effet de serre liées au transport et à la production alimentaire puisque les fruits et légumes de saison nécessitent moins d'énergie pour être cultivés, ils sont adaptés aux conditions climatiques et ne nécessitent pas d'être cultivés sous serre ou dans des conditions artificielles.\n" +
      "La cuisine fait maison soutient l'agriculture locale et encourage la production alimentaire plus durable et respectueuse de l'environnement.\n",
      "La cuisine faite maison a une longue histoire de tradition et de coutumes dans de nombreuses cultures à travers le monde. La préparation de plats traditionnels ou de recettes transmises de génération en génération peut être un moyen de maintenir des liens avec sa culture ou son héritage familial. Il est donc important de préserver ce patrimoine gastronomique et d'inciter cette pratique culinaire, en proposant des cours de cuisine ou des ateliers, en partageant des recettes entre famille ou via les réseaux sociaux et en favorisant l’achat de produits locaux et de saison.\n",
      "Le fait de préparer soi-même un plat peut générer une sensation de plaisir via la création des plats décorés, cette activité créative peut offrir une expérience tactile et visuelle agréable et stimulante qui peut aider à réduire le stress du quotidien et à stimuler la créativité.\n" +
      "Le fait de cuisiner apporte une sensation de bien-être et de satisfaction et également un sentiment de contrôle sur ce que l’on mange. Cette sensation de créer quelque chose de ses propres mains est gratifiante et peut renforcer l'estime de soi ainsi qu'une satisfaction accrue lorsque le résultat est délicieux.\n" +
      "En résumé, le fait maison peut offrir une expérience neurosensorielle bénéfique en stimulant les sens et en promouvant une sensation de bien-être et de satisfaction.\n",
      "L’image de la cuisine est souvent associée à la convivialité, à une atmosphère chaleureuse et accueillante. Elle est généralement le cœur de la maison, où l'on peut se réunir en famille ou entre amis pour discuter, échanger des idées, rire et passer du temps ensemble. De plus, la préparation des repas peut aussi être un moment de partage, de collaboration où chacun peut contribuer à la création du repas. Cela peut renforcer les liens entre les personnes et favoriser un sentiment d'appartenance.\n" +
      "Alors qu'attendez-vous ? Préparez vos tables et invitez vos proches à un dîner convivial !\n" +
      "\n" +
      " \n" +
      "Rien de mieux que de préparer vous-même vos repas pour vous faire plaisir et prendre soin de votre santé, c’est de loin la meilleure façon de contrôler ce que vous mangez. Alors, pourquoi ne pas enfiler votre tablier et commencer à cuisiner dès maintenant ?\n" +
      "N'hésitez plus, entrez dans la cuisine et lancez-vous ! Vous ne serez pas déçu(e) du résultat !\n"

    ],
    references:[
      'Mills et al. International Journal of Behavioral Nutrition and Physical Activity (2017) 14:109,  DOI 10.1186/s12966-017-0567-y \n',
      "IPSOS - Les Français économisent en moyenne 272 euros par an grâce au Do it yourself\n",
      "INRAE Institut national de recherche pour l'agriculture, l'alimentation et l'environnement \n",
      "Tharrey, M., Drogué, S., Privet, L., Perignon, M., Dubois, C., & Darmon, N. (2020). Industrially processed v. home-prepared dishes: What economic benefit for the consumer? Public Health Nutrition, 1-9. doi:10.1017/S1368980019005081\n",
      "Santé -  pourquoi privilégier le fait maison?\n",
      "Étude réalisée par Ipsos pour l’Observatoire E. Leclerc des Nouvelles Consommations sur un échantillon national de 1 039 Français représentatif de la population âgée de 18 ans et plus, interrogés du 10 au 13 janvier 2019 en ligne selon la méthode des quotas.\n"
    ],
    links: [
      'https://www.researchgate.net/publication/319166243_Frequency_of_eating_home_cooked_meals_and_potential_benefits_for_diet_and_health_Cross-sectional_analysis_of_a_population-based_cohort_study\n',
      "https://www.ipsos.com/fr-fr/les-francais-economisent-en-moyenne-272-euros-par-grace-au-do-it-yourself\n",
      "https://www.inrae.fr/actualites/comparaison-couts-entre-plat-fait-maison-plat-industriel  \n",
      "https://www.mangerbouger.fr/manger-mieux/se-faire-plaisir-en-mangeant-equilibre/cuisiner-maison/pourquoi-privilegier-le-fait-maison\n"
    ],

    comments:[
      {
        author:'Nassim merahba',
        posted_at:'25 janvier 2023',
        comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
      },
      {
        author:'Mehdi Menacer',
        posted_at:'30 janvier 2023',
        comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
      }
    ]
  },
  // {
  //   id:1,
  //   title:'Les différentes types de Pain',
  //   author:'author_2',
  //   posted_at:'16 Mars 2023',
  //   img:'/assets/blogimag2.png',
  //   txt:'Le pain est un aliment phare de l\'alimentation. Bon ou mauvais pour la santé, tout dépend de son mode de fabrication et des ingrédients qui le composent...\nOn entend régulièrement dire qu il est important de manger des légumes, et ce n’est pas pour rien ! Les légumes contiennent des éléments dont notre corps a besoin pour fonctionner normalement, notamment des fibres, des vitamines et des minéraux.Mais alors, comment profiter de tous les bienfaits des légumes au quotidien ? L’OMS (Organisation Mondiale de la Santé), recommande de consommer au moins 5 portions de fruits et légumes par jour, soit 400 g. Cette consommation journalière de fruits et légumes permettrait de réduire le risque de maladies non transmissibles et de garantir un apport suffisant en fibres.\nLes légumes sont le groupe alimentaire qui devrait occuper le plus de place dans votre assiette. Ils sont bourrés de vitamines, de minéraux, de fibres et de phytonutriments qui aident à combattre certaines maladies, maintenir un poids santé et ralentir le vieillissement. Sans légumes, vous privez votre organisme d’un éventail de micronutriments bénéfiques pour être en bonne santé.\nLes légumes ont la combinaison parfaite ! Ils contiennent énormément de fibres, ils sont peu caloriques et ils regorgent de nutriments. En d’autres mots, lorsqu’on les mange, ils nous “bourrent” plus, ils nous aident à maintenir un poids santé tout en nous donnant ce dont notre corps a besoin pour bien fonctionner. Bingo!\nManger un arc-en-ciel d’aliments vous assurera un large éventail de vitamines, de minéraux et de nutriments qui sont fournis par chaque groupe de couleurs. Manger tous les cinq groupes de couleur chaque jour est un excellent moyen de garder une bonne santé et de se sentir mieux!',
  //   tags:['NutriCT','Santé','Recette','Nutrition'],
  //   comments:[
  //     {
  //       author:'Nassim merahba',
  //       posted_at:'25 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     },
  //     {
  //       author:'Mehdi Menacer',
  //       posted_at:'30 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     }
  //   ]
  // },
  // {
  //   id:2,
  //   title:'Fast Food aujourd\'hui',
  //   author:'author_3',
  //   posted_at:'16 juillet 2023',
  //   img:'/assets/blogimg3.png',
  //   txt:'Le pain est un aliment phare de l\'alimentation. Bon ou mauvais pour la santé, tout dépend de son mode de fabrication et des ingrédients qui le composent...\nOn entend régulièrement dire qu il est important de manger des légumes, et ce n’est pas pour rien ! Les légumes contiennent des éléments dont notre corps a besoin pour fonctionner normalement, notamment des fibres, des vitamines et des minéraux.Mais alors, comment profiter de tous les bienfaits des légumes au quotidien ? L’OMS (Organisation Mondiale de la Santé), recommande de consommer au moins 5 portions de fruits et légumes par jour, soit 400 g. Cette consommation journalière de fruits et légumes permettrait de réduire le risque de maladies non transmissibles et de garantir un apport suffisant en fibres.\nLes légumes sont le groupe alimentaire qui devrait occuper le plus de place dans votre assiette. Ils sont bourrés de vitamines, de minéraux, de fibres et de phytonutriments qui aident à combattre certaines maladies, maintenir un poids santé et ralentir le vieillissement. Sans légumes, vous privez votre organisme d’un éventail de micronutriments bénéfiques pour être en bonne santé.\nLes légumes ont la combinaison parfaite ! Ils contiennent énormément de fibres, ils sont peu caloriques et ils regorgent de nutriments. En d’autres mots, lorsqu’on les mange, ils nous “bourrent” plus, ils nous aident à maintenir un poids santé tout en nous donnant ce dont notre corps a besoin pour bien fonctionner. Bingo!\nManger un arc-en-ciel d’aliments vous assurera un large éventail de vitamines, de minéraux et de nutriments qui sont fournis par chaque groupe de couleurs. Manger tous les cinq groupes de couleur chaque jour est un excellent moyen de garder une bonne santé et de se sentir mieux!',
  //   tags:['NutriCT','Santé','Recette','Nutrition'],
  //   comments:[
  //     {
  //       author:'Nassim merahba',
  //       posted_at:'25 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     },
  //     {
  //       author:'Mehdi Menacer',
  //       posted_at:'30 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     }
  //   ]
  // },
  // {
  //   id:3,
  //   title:'Le secret des fruits',
  //   author:'author_4',
  //   posted_at:'04 Avril 2023',
  //   img:'/assets/blogimg4.png',
  //   txt:'Le pain est un aliment phare de l\'alimentation. Bon ou mauvais pour la santé, tout dépend de son mode de fabrication et des ingrédients qui le composent...\nOn entend régulièrement dire qu il est important de manger des légumes, et ce n’est pas pour rien ! Les légumes contiennent des éléments dont notre corps a besoin pour fonctionner normalement, notamment des fibres, des vitamines et des minéraux.Mais alors, comment profiter de tous les bienfaits des légumes au quotidien ? L’OMS (Organisation Mondiale de la Santé), recommande de consommer au moins 5 portions de fruits et légumes par jour, soit 400 g. Cette consommation journalière de fruits et légumes permettrait de réduire le risque de maladies non transmissibles et de garantir un apport suffisant en fibres.\nLes légumes sont le groupe alimentaire qui devrait occuper le plus de place dans votre assiette. Ils sont bourrés de vitamines, de minéraux, de fibres et de phytonutriments qui aident à combattre certaines maladies, maintenir un poids santé et ralentir le vieillissement. Sans légumes, vous privez votre organisme d’un éventail de micronutriments bénéfiques pour être en bonne santé.\nLes légumes ont la combinaison parfaite ! Ils contiennent énormément de fibres, ils sont peu caloriques et ils regorgent de nutriments. En d’autres mots, lorsqu’on les mange, ils nous “bourrent” plus, ils nous aident à maintenir un poids santé tout en nous donnant ce dont notre corps a besoin pour bien fonctionner. Bingo!\nManger un arc-en-ciel d’aliments vous assurera un large éventail de vitamines, de minéraux et de nutriments qui sont fournis par chaque groupe de couleurs. Manger tous les cinq groupes de couleur chaque jour est un excellent moyen de garder une bonne santé et de se sentir mieux!',
  //   tags:['NutriCT','Santé','Recette','Nutrition'],
  //   comments:[
  //     {
  //       author:'Nassim merahba',
  //       posted_at:'25 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     },
  //     {
  //       author:'Mehdi Menacer',
  //       posted_at:'30 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     }
  //   ]
  // },
  // {
  //   id:4,
  //   title:'Le sport et la nutrition',
  //   author:'author_5',
  //   posted_at:'30 Octobre 2023',
  //   img:'/assets/blogimg5.png',
  //   txt:'Les risques du fast-food en général En plus de l’obésité, les maladies cardiaques et le diabète, il y a aussi l’hypertension artérielle, certains cancers, des troubles du sommeil et des risques de dépression.....\nOn entend régulièrement dire qu il est important de manger des légumes, et ce n’est pas pour rien ! Les légumes contiennent des éléments dont notre corps a besoin pour fonctionner normalement, notamment des fibres, des vitamines et des minéraux.Mais alors, comment profiter de tous les bienfaits des légumes au quotidien ? L’OMS (Organisation Mondiale de la Santé), recommande de consommer au moins 5 portions de fruits et légumes par jour, soit 400 g. Cette consommation journalière de fruits et légumes permettrait de réduire le risque de maladies non transmissibles et de garantir un apport suffisant en fibres.\nLes légumes sont le groupe alimentaire qui devrait occuper le plus de place dans votre assiette. Ils sont bourrés de vitamines, de minéraux, de fibres et de phytonutriments qui aident à combattre certaines maladies, maintenir un poids santé et ralentir le vieillissement. Sans légumes, vous privez votre organisme d’un éventail de micronutriments bénéfiques pour être en bonne santé.\nLes légumes ont la combinaison parfaite ! Ils contiennent énormément de fibres, ils sont peu caloriques et ils regorgent de nutriments. En d’autres mots, lorsqu’on les mange, ils nous “bourrent” plus, ils nous aident à maintenir un poids santé tout en nous donnant ce dont notre corps a besoin pour bien fonctionner. Bingo!\nManger un arc-en-ciel d’aliments vous assurera un large éventail de vitamines, de minéraux et de nutriments qui sont fournis par chaque groupe de couleurs. Manger tous les cinq groupes de couleur chaque jour est un excellent moyen de garder une bonne santé et de se sentir mieux!',
  //   tags:['NutriCT','Santé','Recette','Nutrition'],
  //   comments:[
  //     {
  //       author:'Nassim merahba',
  //       posted_at:'25 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     },
  //     {
  //       author:'Mehdi Menacer',
  //       posted_at:'30 janvier 2023',
  //       comment:'Bonjour Dr Taleb , je vous remercie pour l’article'
  //     }
  //   ]
  // },
]
