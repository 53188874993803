<template>
  <div>
    <div class="main-body flex flex-col items-center flex-wrap mb-12 p-6 " >
      <div class="mb-28">
        <h1 class="font-extrabold text-4xl">Le déroulement des consultations</h1>
      </div>
      <div class="flex flex-row w-full flex-wrap items-center justify-center mb-12" >

        <div class=" w-5/6 p-6" style="background: rgba(201, 239, 209, 0.23); font-family: 'Aleo', serif;">
          <div id="card-title" class="section-title">Consultation Bilan</div>
          <div >
            <p class=" font-bold text-xl text-center mt-8 ">C’est un bilan complet qui prend en compte tous les facteurs influençant votre alimentation et votre bien être <br/>physique et mentale.
            <br/>
              Cette consultation va être déroulée en 5 étapes s’étale sur un volume horaire de <span class="text-green-700">1h30</span></p>
          </div>
          <div class="flex flex-col flex-wrap ">
            <div class="flex flex-row mt-5 mb-5" v-for="(item ,i) in consultationBilan" :key="i" >
              <img :src='assetPath+"/assets/nct-leaf.svg"' class="mr-2">
              <p class="description" style="font-weight: bold; " >{{item.text}}</p>
            </div>
          </div>
          <div class="flex justify-center">
            <p class="text-center font-bold text-xl mt-8 w-9/12">Ainsi qu’une amélioration de votre qualité de vie par le biais des 5 facteurs de vivants !</p>
          </div>

        </div>
      </div>
      <div class="flex flex-row w-full flex-wrap items-center justify-center mt-12" >
            <div class=" w-5/6 p-6 " style="background: rgba(201, 239, 209, 0.23); font-family: 'Aleo', serif;" >
                <div id="card-title" class="section-title" style="font-size: 20px">Consultation de suivi nutritionnel</div>
                <div >
                    <p class="text-center font-bold text-xl mt-8 ">Une séance d’évaluation et de coaching nutritionnel, elle s’étale sur une durée de <span class="text-green-700" >40 min.</span></p>

                </div>
                <div class="flex flex-col flex-wrap ">
                    <div v-for="(item ,i) in consultationSn" :key="i"  class="flex flex-row flex-wrap mb-3 mt-3">
                        <img :src='assetPath+"/assets/nct-leaf.svg"' class="mr-2">
                        <p class="description" style="font-weight: bold;" >{{item.text}}</p>
                    </div>
                </div>

            </div>
    </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "seance",
  data() {
    return {
      step: 1,
      consultationBilan : [
        {
          id:0,
          text:"Découverte active et humaine du patient/ de la patiente: une approche humaine afin de vous mieux connaître et de comprendre votre problématique, vous serez écouté en confiance, rassuré et déjà accompagné."
        },
        {
          id:1,
          text:"Analyse médicale: je recueille toutes les données médicales et j’analyse votre profil santé cardio-vasculaire et générale afin d’établir un bilan prévention-santé."
        },
        {
          id:2,
          text:"Analyse technique: Je recueille et j’analyse vos mensurations corporelles."
        },
        {
          id:3,
          text:"Analyse Nutritionnelle: J’analyse votre carnet alimentaire."
        },
        {
          id:4,
          text:"Solution nutritionnelle et pleine santé: Je vous propose un plan alimentaire 100% personnalisé selon vos besoins nutritionnels, vos habitudes et vos goûts personnels."
        }
      ],
      consultationSn : [
        {
          id:0,
          text:"Bilan des jours précédents."
        },
        {
          id:1,
          text:"Vérification des objectifs atteints."
        },
        {
          id:2,
          text:"Mise en place de nouveaux objectifs."
        },
        {
          id:3,
          text:"Atelier éducatif avec des outils de nutrition-santé sur les points à travailler."
        },
        {
          id:4,
          text:"Le coaching pleine santé."
        },

      ]
    }
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    }
  }
}
</script>
<style scoped>
@import '../../../sass/components/about.css';

</style>
