<script setup>
</script>

<template>
  <div class="footer-container">
    <div class="flex items-center lg:justify-between justify-center flex-wrap">
      <div class="mx-5 mb-5">
        <div class="">
          <router-link to="/" @click="scrollToTop">
            <img :src='assetPath+"/assets/nct-white-logo.svg"' class="footer-logo">
          </router-link>
          <p class="footer-text">Consultation en Nutrition santé et bien-être</p>
        </div>
        <div class="social-media-cont">
          <a>
            <a href="https://www.facebook.com/Nutrition.Coachingtech" target="_blank"><img :src='assetPath+"/assets/facebook-logo.svg"' class="socials-icon"></a>
          </a>
          <a>
            <a href="https://www.instagram.com/nutrition_coachingtech/" target="_blank"><img :src='assetPath+"/assets/instagram-logo.svg"' class="socials-icon"></a>
          </a>
          <a>
            <a href="https://www.linkedin.com/in/zineb-taleb-92ba3a245/" target="_blank"><img :src='assetPath+"/assets/linkedin-logo.svg"' class="socials-icon"></a>
          </a>
        </div>
      </div>
      <div class="lg:pl-20 mx-5 mt-3">
        <router-link to="/" style="text-decoration: none" @click="scrollToTop">
            <p class="navigation-text">Accueil</p>
        </router-link>
        <router-link to="/about" style="text-decoration: none" @click="scrollToTop">
            <p class="navigation-text">A propos</p>
        </router-link>
        <router-link to="/seance" style="text-decoration: none" @click="scrollToTop">
          <p class="navigation-text">Consultation</p>
        </router-link>
        <router-link to="/consultation" style="text-decoration: none" @click="scrollToTop">
          <p class="navigation-text">Tarif</p>
        </router-link>
        <router-link to="/nutrition" style="text-decoration: none" @click="scrollToTop">
            <p class="navigation-text">Blog</p>
        </router-link>
        <router-link to="/faq" style="text-decoration: none" @click="scrollToTop">
          <p class="navigation-text">FAQ</p>
        </router-link>

      </div>
      <div class="contact-section ml-5">
        <div class="contact-element">
          <div class="mr-2">
            <img :src='assetPath+"/assets/message.svg"' class="footer-icon"/>
          </div>
          <a class="contact-text" href ="mailto:contact@nutrict.co">contact@nutrict.co</a>
        </div>
        <div class="contact-element">
          <div class="mr-2">
<!--          <img :src='assetPath+"/assets/phone.svg"' class="footer-icon"/>-->
          </div>
<!--          <a class="contact-text" href="tel:0033764463522">0033764463522</a>-->
        </div>
      </div>
    </div>
    <div class="flex items-center flex-wrap lg:justify-end justify-center">

      <a class="lpg-text" href="https://nutrict.co/policy" target="_blank">Mentions Légales</a>
      <!--      <a class="lpg-text lg:pl-20 sm:pl-10" href="">Politique de confidentialité</a>
            <a class="lpg-text lg:pl-20 sm:pl-10" href="javascript:void(0)">Gestion des cookies</a>-->
    </div>
  </div>
</template>
<script>
export default {

  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
  methods:{
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  }
}
</script>
<style scoped>
@import '../../../sass/components/footer.css';
</style>
