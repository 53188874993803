<template>
  <div class="container" >
    <swiper
      ref="Temoignage"
      :effect="'coverflow'"
      :grabCursor="false"
      :centeredSlides="true"
      :slidesPerView="3"
      :spaceBetween="1"
      :loop="true"
      :breakpoints="{
          '320': {slidesPerView: 1,spaceBetween: 20,},
          '516': {slidesPerView: 1,spaceBetween: 1,},
          '868': {slidesPerView: 1,spaceBetween: 1,},
          '945': {slidesPerView: 3,spaceBetween: 1,},
          '1025': {slidesPerView: 3,spaceBetween: 20,},
        }"
      :coverflowEffect="{
          rotate: 0,
          stretch: 50,
          depth: 150,
          modifier: 3,
          slideShadows: false,
        }"
      :pagination="{
          el:'.swiper-pagination',
          clickable: true
        }"
      :navigation="true"
      :modules="modules"
      class="mySwiper"
      style="height: 550px ;"
    >
      <swiper-slide v-for="item in Temoignage"
                    :key="item.id" class="swiper-slide-t"  v-slot="{ isActive }" >
        <transition enter-from-class="opacity-0"
                    leave-to-class="opacity-0"
                    enter-active-class="transition duration-500"
                    leave-active-class="transition duration-400">
          <div  class="bg-white flex flex-col items-center mt-2 shadow-lg shadow-green-700/50 h-full px-2 mb-1 sm:h-full w-full md:h-full w-3/4">

            <div class="w-3/4 h-3/4 flex flex-col items-center mt-4 mb-8">
              <img :src="assetPath+item.img" style="width: 80px;height: 80px; border-radius: 99px; border-width:2px; border-color: green; "/>
              <p class="text-green-600 text-2xl font-bold">{{item.user}}</p>
              <p class="text-gray-500 text-center">{{item.comment}}</p>
            </div>
            <star-rating v-model:rating="item.rating"
                         :show-rating="false"
                         :read-only="true"
                         :rounded-corners="true"
                         border-color="#238C56"
                         active-color="#238C56"
                         star-size="30" ></star-rating>
          </div>
        </transition>
      </swiper-slide>
    </swiper>
  </div>

</template>
<script>

import {Swiper, SwiperSlide} from "swiper/vue";
import {Temoignage} from "../constants/Temoignage";
import StarRating from 'vue-star-rating'
import "swiper/css";

import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import {EffectCoverflow, Pagination, Navigation} from "swiper";

export default {
  components: {
    Swiper,
    SwiperSlide,
    StarRating
  },
  setup() {

    return {
      modules: [Navigation,EffectCoverflow, Pagination ],
    };
  },
  data () {
    return {
      Temoignage,
    };
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    }
  }
};

</script>

<style>


.swiper-slide-t {
  width: 450px;
  height: 400px;
  transition:all 200ms linear;
  transform: scale(0.8);

}


.img-active {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 400px;
  transition:all 200ms linear;
  transform: scale(1);
  display: block;
  width: 100%;
  border-bottom: 20px solid green !important;
  border-right: 20px solid green !important;
}

.img {
  background-position: center;
  background-size: cover;
  width: 300px;
  height: 300px;
  transition:all 200ms linear;
  transform: scale(0.8);
  display: block;
  width: 100%;
  border-radius: 30px;
  opacity: 60%;

}
.slider-text {
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  text-indent: 6px;
  color: #695E37;
}

.container {
  width: 100%;
  height: 20%;
  margin-bottom: 10px;
}
.swiper-button-prev {
  color: green;
}

.swiper-button-next {
  color: green;

}
:root {
  --swiper-navigation-size: 75px;
}

</style>
