<template>
  <header class="mb-4">
    <nav class="flex flex-wrap justify-between items-center w-[92%] mx-auto p-6">
      <div>
        <router-link to="/">
          <img class="w-52" :src='assetPath+"/assets/nct-green-logo-flat.svg"' alt="...">
        </router-link>
      </div>
      <div class="flex items-center gap-6">
        <router-link to="/contact-us">
          <button class="max-lg:hidden header-btn hover:bg-green-700">Contactez-nous</button>
        </router-link>
        <router-link to="/login-portal" v-show="false">
        <button class="max-lg:hidden header-btn hover:bg-green-700">Se connecter</button>
      </router-link>

        <img class="w-6 cursor-pointer lg:hidden" :src="onToggleMenu ? assetPath+'/assets/close.svg' : assetPath+'/assets/menu.svg'"  alt="..." @click="onToggleMenu = !onToggleMenu">
      </div>
      <div @mouseleave="onToggleMenu = false" :class="onToggleMenu ?'lg:static absolute bg-white lg:min-h-fit min-h-[60vh] left-0 top-[23%] lg:w-auto w-full flex items-center px-16 z-10' : 'lg:static absolute bg-white lg:min-h-fit min-h-[60vh] left-0 top-[-100%] lg:w-auto w-full flex items-center mt-12 flex-wrap'">
        <ul class="flex lg:flex-row flex-col lg:items-center lg:gap-[8vw] gap-8">

          <li>
            <router-link to="/contact-us">
              <a @click="onToggleMenu = false" class="lg:hidden header-text hover:text-green-700" href="#">Contactez-nous</a>
            </router-link>
          </li>
          <li class="pb-1">
            <router-link to="/">
              <a @click="onToggleMenu = false" :class="currentRoute === '/' ? 'text-green-700 font-bold text-base': 'text-gray-500 font-bold text-base hover:text-green-700'" href="#">Accueil</a>
              <hr v-show="currentRoute === '/'" class="line-green">
            </router-link>
          </li>
          <li class="pb-1">
            <router-link to="about">
              <a @click="onToggleMenu = false" :class="currentRoute === '/about' ? 'text-green-700 font-bold text-base': 'text-gray-500 font-bold text-base hover:text-green-700'" href="#">A propos</a>
              <hr v-show="currentRoute === '/about'" class="line-green">
            </router-link>
          </li>
          <li class="pb-1">
            <router-link to="/seance"  >
              <a @click="onToggleMenu = false" :class="currentRoute === '/seance' ? 'text-green-700 font-bold text-base': 'text-gray-500 font-bold text-base hover:text-green-700'" href="#">Consultation</a>
              <hr v-show="currentRoute ==='/seance'" >
            </router-link>
          </li>
          <li class="pb-1">
            <router-link to="/consultation"  >
              <a @click="onToggleMenu = false" :class="currentRoute === '/consultation' ? 'text-green-700 font-bold text-base': 'text-gray-500 font-bold text-base hover:text-green-700'" href="#">Tarif</a>
              <hr v-show="currentRoute ==='/consultation'" >
            </router-link>
          </li>

          <li class="flex relative pb-1" @mouseenter="showMenuBlog = true" @mouseleave="showMenuBlog = false">
           <button class="flex header-text hover:text-green-700 outline-none focus:outline-none">Blog
<!--              <i class="fa-solid fa-caret-down ml-2 mt-1 text-gray-500 hover:text-green-700"></i>-->
            </button>
            <div class="lg:absolute top-7 left-0 drop-menu p-2 z-20" :class="{'hidden':!showMenuBlog, 'flex flex-col':showMenuBlog}">
              <ul class="space-y-3 w-40">
                <li class="flex items-center rounded-md hover:bg-gray-100 p-1">
                  <router-link to="/nutrition" class="flex items-center">
                    <img :src='assetPath+"/assets/infos.svg"' alt="...">
                    <a href="#" class="header-text hover:text-green-700 pl-1">Infos nutrition</a>
                  </router-link>

                </li>
<!--                 <li class="flex items-center rounded-md hover:bg-gray-100 p-1">
                    <router-link to="">
                      <img :src='assetPath+"/assets/recipes.svg"' alt="...">
                      <a href="#" class="header-text hover:text-green-700 pl-1">Recettes</a>
                    </router-link>

                  </li>-->
              </ul>
            </div>
          </li>

          <li class="pb-1">
            <router-link to="/faq">
              <a @click="onToggleMenu = false" :class="currentRoute === '/faq' ? 'text-green-700 font-bold text-base': 'text-gray-500 font-bold text-base hover:text-green-700'"  href="#">FAQ</a>
              <hr v-show="currentRoute === '/faq'" >
            </router-link>

          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
export default {
  data() {
    return {
      showMenuApropo: false,
      showMenuConsultation: false,
      showMenuBlog: false,
      showMenuInfo: false,
      onToggleMenu: false,

    }
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    assetPath() {
      return process.env.ASSET_PATH || "";
    }
  },
};

</script>
<style scoped>
  @import '../../../sass/components/header.css';
</style>

