<template>
  <div>
    <div>
    <div class="main-body flex flex-col items-start content-center flex-wrap">
      <div class="flex flex-row max-w-full items-center flex-wrap">
        <div class="text-container p-6 mb-8">
          <div id="card-title" class="section-title">Parcours et Formation</div>
          <p style="line-height: 35px; text-indent: 15px">
            Diplômée d’un doctorat en médecine générale d'état Algérien, j’ai candidaté et réussi  à l’épreuve de formation en post-graduation médicale et j’ai opté pour le choix de la  psychiatrie générale. La première année étant enrichissante en termes de névroses obsessionnelles, les troubles d’humeurs, les troubles de conduites alimentaires et les pathologies du sommeil. Ce parcours de formation hospitalière au sein de service d’urgence et la consultation psychiatrique m’a fait découvrir mon attrait pour la nutrition et son impact sur le bien être psychique et physique de la personne.<br/>
            Passionnée et désireuse de piocher dans cette discipline j’ai mis fin aux études post-universitaires.<br/>
            J’ai candidaté à une formation de nutrition basée sur les principes des neurosciences appliquées issues des cinq facteurs du vivant de l'école française 5.3 de nutrition, sport et santé. Une formation qui a été couronnée de succès avec un certificat d’accompagnement en nutrition-santé mention félicitations et honneur du jury.<br/>
            Actuellement je suis en train de suivre une formation universitaire à Paris Descartes afin d’obtenir un diplôme interuniversitaire européen en micronutrition, alimentation, prévention et santé.<br/>
            Mes connaissances acquises durant mon parcours d’études médicales et les formations suivies ont alimenté mes compétences et ma capacité à apporter une offre de qualité professionnelle et humaine. Confiante, j’ai décidé de me lancer dans l’accompagnement nutritionnel individualisé et j’ai créé ma propre activité professionnelle en tant que consultante en nutrition-santé.<br/>
            Motivée par la conviction que chacun a le potentiel de se sentir bien - mentalement et physiquement - je me réjouis d'aider les gens à atteindre leurs objectifs grâce à des conseils et à un soutien nutritionnel avisé.<br/>
            La prévention est un élément clé pour moi, c'est la raison pour laquelle j’opte pour une démarche nutritionnelle préventive intégrée à un programme d'accompagnement personnalisé.
          </p>
        </div>
        <div class="flex flex-col">
          <p class="name-p mb-5">Dr Zineb Taleb</p>
          <p class="diplomat mb-5">
            Médecin généraliste <br />
            Consultante en Nutrition <br />Co-Fondatrice de Nutrict
          </p>
          <br/>
          <a class="nav-link" href='mailto:zineb@nutrict.co'>
            <button class="contact-btn hover:bg-green-600" style="margin-top: -30px" >
              Contactez-moi
            </button>
          </a>
        </div>
      </div>
      <div class="flex flex-col items-start flex-wrap mt-5">
      <div class="flex flex-row justify-start items-center mt-4">
        <div class="text-container p-12 mb-8">
          <div id="card-title" class="section-title">Histoire de NutriCT</div>
          <p style="line-height: 35px; text-indent: 15px">
            <span style="color: #238c56">NutriCT</span> a commencé par l'idée de créer une solution d'accompagnement  la plus disponible et la plus proche aux personnes qui ont besoin d’orientation et d’aide pour gérer leur alimentation d'où son appellation Nutrition Coaching Technologie.
            NutriCT a pu voir la lumière du jour grâce à une collaboration fructueuse avec mon conjoint, étant un ingénieur en informatique, il a pu concrétiser l’idée de NutriCT en tant qu’une solution révolutionnaire de Nutrition et bien-être humaine.
            Actuellement, NutriCT continue d’avancer grâce à un travail harmonieux et enthousiaste de toute une équipe formidable .
            <br/>
            Mon travail sur NutriCT consiste alors à de sensibiliser les gens sur l'importance d'une alimentation équilibrée et adaptée et à l'impact positif qu'elle peut avoir sur leur vie, à aider toute personne qui souhaite reprendre son alimentation en main et bénéficier de conseils hygiéno-diététiques adaptés à son profil.Et c'est un vrai bonheur de voir les gens transformer leur vie en mieux après avoir
            travaillé avec eux sur leur alimentation et leur hygiène de vie.

          </p>
          <div class="w-full flex justify-end">
            <img id="leaf-logo" :src="assetPath + '/assets/nct-leaf-large.svg'" class="mt-3 leaf-icon" style="width: 60px" alt=""/>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    <div class="carousel-container mb-44">
      <div class="flex flex-row justify-center items-center">
        <div class="carousel-box">
          <div id="objectives" class="carousel-title">Mes Objectifs</div>
          <div v-if="step === 1" class="px-5 py-10 fade-in-out">
            <p class="obj-text">
              En fonction de vos objectifs, je vous propose des programmes alimentaires & bien-être personnalisés, en initiant la démarche par la réalisation d’un bilan nutritionnel complet qui prend en compte tous les facteurs ayant un impact sur votre alimentation. Je vous propose un accompagnement complet qui vise l'aspect alimentaire principalement mais aussi les autres facteurs de la pleine santé.Ensemble nous identifions les points qui bloquent dans votre hygiène de vie et nous les corrigeons étapes par étapes.
            </p>
          </div>
          <div v-if="step === 2" class="px-5 py-10 fade-in-out" style="margin-bottom: 30px">
            <p class="obj-text">
              Je vous accompagnerai dans votre démarche pour modifier vos habitudes alimentaires de manière durable. J'ai pu constater de visu la rapidité avec laquelle mes patients  bénéficient de ces changements, tant sur le plan physique que mental. Une fois qu'ils ont compris pourquoi prendre soin de leur assiette signifie nourrir leur corps et leur esprit, ils sont prêts à faire des changements durables ! c’est votre tour maintenant !
              <br/>
              Si vous cherchez quelqu'un qui s'occupe de votre alimentation et de votre santé, vous pouvez compter sur moi!
            </p>
          </div>
          <div class="flex justify-end items-center pr-5 mb-4">
            <button class="carousel-btn mr-2" :style="step === 1 ? '' : 'background: #238C56;'" @click="step = 1" >
              <img :src=" step === 1 ? assetPath + '/assets/left-gray-arrow.svg' : assetPath + '/assets/left-white-arrow.svg' " alt="..." />
            </button>
            <button class="carousel-btn" :style="step === 2 ? '' : 'background: #238C56;'" @click="step = 2" >
              <img :src="step === 2 ? assetPath + '/assets/right-gray-arrow.svg' : assetPath + '/assets/right-white-arrow.svg'" alt="..."/>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "About",
  data() {
    return {
      step: 1,
    };
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    },
  },
};
</script>
<style scoped>
@import "../../../sass/components/about.css";
</style>
