<template>
  <div class="main-body max-sm:px-2">
    <div>
      <h1 class="font-extrabold text-green-700 text-4xl">FAQ</h1>
      <div class="w-full flex flex-col mt-6 p-10 max-sm:p-2" style="background: rgba(35, 140, 86, 0.18);">
        <FaqComponent v-for="item in qan " :key="item.id" :question="item.question" :answer="item.answer"/>
      </div>
    </div>
  </div>
</template>
<script>
import FaqComponent from "./FaqComponent.vue";
export default {
  name: "faq",
  components: {FaqComponent},
  data() {
    return {
      step: 1,
      qan : [
        {
          id:0,
          question:"Qu'est-ce qu’un consultant en nutrition ",
          answer:"Un consultant en nutrition est un professionnel de la santé qui se spécialise dans la nutrition et l’accompagnement alimentaire personnalisé . Sa mission principale consiste à aider les gens à améliorer leur alimentation et leur mode de vie pour atteindre leurs objectifs durablement en matière de nutrition, de santé et de bien-être"
        },
        {
          id:1,
          question:"Qu'est-ce que l'accompagnement en nutrition ?",
          answer:"L'accompagnement en nutrition est un service qui consiste à aider les personnes à atteindre leurs objectifs en matière de nutrition et de santé. Cela peut inclure des conseils sur la planification de repas, la correction de certaines habitudes alimentaires, l'ajout de nouveaux aliments à leur mode alimentaire, la réduction de la consommation d'aliments malsains et la mise en place de routines d'exercice appropriées."
        },
        {
          id:2,
          question:"Comment un nutritionniste peut-il m'aider à atteindre mes objectifs de santé ?",
          answer:"Il peut vous aider à atteindre vos objectifs de santé en vous offrant des conseils personnalisés en matière de nutrition et en vous aidant à élaborer un plan alimentaire personnalisé en fonction de vos besoins, de votre mode de vie et de vos préférences alimentaires. Il peut également vous fournir des conseils sur la gestion du poids, vous aider à comprendre les étiquettes des aliments, à faire des choix alimentaires sains et à préparer des repas nutritifs dont le but est de créer un mode de vie sain."
        },
        {
          id:3,
          question:"Est-ce que je dois consulter un nutritionniste si je suis en bonne santé ?",
          answer:" Même si vous êtes en bonne santé, un nutritionniste peut vous aider à maintenir votre santé à long terme et à prévenir diverses maladies notamment les pathologies cardiovasculaires…  en vous aidant à faire des choix alimentaires adéquats et à comprendre comment les aliments affectent votre corps. Si vous avez des questions sur la nutrition ou si vous cherchez à améliorer votre alimentation, un nutritionniste peut vous aider."
        },
        {
          id:4,
          question:"Comment fonctionne le processus de coaching en nutrition ?",
          answer:"Le processus de coaching en nutrition commence souvent par une consultation de bilan:  vous discuterez de vos antécédents médicaux, de vos habitudes alimentaires et de votre mode de vie actuel y compris votre niveau d’activité physique ainsi que de vos objectifs en matière de santé. Lors de cette première consultation un journal alimentaire de 3 jours minimum est indispensable ainsi qu'à vos mensurations récentes ( le poids en Kg, la taille en Cm, le périmètre abdominal en Cm). Ensuite, le coach travaillera avec vous pour élaborer un plan alimentaire personnalisé, vous fournira des conseils et un soutien tout au long du processus. Des consultations de suivi régulières peuvent être indispensables  pour vous aider à atteindre vos objectifs de santé et d’ajuster le plan nutritionnel en fonction des résultats."
        },
        {
          id:5,
          question:"Quelle est la différence entre le tour de taille et le périmètre abdominal ?",
          answer:"Bien qu'ils soient tous deux des mesures de la circonférence abdominale, le tour de taille et le périmètre abdominal sont des mesures légèrement différentes Le tour de taille est généralement mesuré à l'endroit le plus étroit de la taille, généralement juste au-dessus du nombril. Cette mesure peut donner une indication de la quantité de graisse sous-cutanée présente dans la région abdominale. Le périmètre abdominal, en revanche, est mesuré à l'endroit le plus large de l'abdomen, souvent au niveau du nombril. Cette mesure peut donner une indication de la quantité de graisse viscérale présente dans la région abdominale, qui est une graisse plus profonde qui entoure les organes internes. Bien que les deux mesures soient utilisées pour évaluer l'obésité abdominale et les risques associés pour la santé, le périmètre abdominal peut être considéré comme une mesure plus complète de la graisse abdominale, car elle prend en compte à la fois la graisse sous-cutanée et la graisse viscérale.",
        },
        {
          id:6,
          question:"Quels types d'objectifs de santé peuvent être atteints grâce à un coaching en nutrition ?",
          answer:"Même si vous êtes en bonne santé, un nutritionniste peut vous aider à maintenir votre santé à long terme et à prévenir diverses maladies notamment les pathologies cardiovasculaires…  en vous aidant à faire des choix alimentaires adéquats et à comprendre comment les aliments affectent votre corps. Si vous avez des questions sur la nutrition ou si vous cherchez à améliorer votre alimentation, un nutritionniste peut vous aider."
        },
        {
          id:7,
          question:"Combien de temps faut-il pour voir des résultats en travaillant avec un coach en nutrition ?",
          answer:"Le temps nécessaire pour voir des résultats dépendra des objectifs de santé spécifiques de chaque individu. Cependant, en général, les gens peuvent commencer à remarquer des changements positifs dans leur corps et leur santé après seulement quelques semaines de travail avec un coach en nutrition."
        },{
          id:8,
          question:"Combien de temps dure l'accompagnement en nutrition ?",
          answer:"La durée de l'accompagnement en nutrition dépend des objectifs de la personne. Certaines personnes peuvent avoir besoin d'un accompagnement régulier sur une période prolongée pour atteindre leurs objectifs, tandis que d'autres peuvent avoir besoin d'un accompagnement plus court pour atteindre des objectifs spécifiques. Votre professionnel en nutrition travaillera avec vous pour élaborer un plan de suivi personnalisé en fonction de vos besoins."
        },
        {
          id:9,
          question:"Quel est le coût d'un coaching en nutrition ?",
          answer:"Le coût d'un coaching en nutrition dépendra des services fournis, de la durée de l'engagement et du niveau de formation et d’expérience du coach en nutrition. Les tarifs peuvent varier considérablement, mais il est important de considérer l'investissement dans votre santé et votre qualité de vie à long terme."
        },


      ]

    }
  },
}
</script>
<style scoped>
@import '../../../sass/components/about.css';

</style>
