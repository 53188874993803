<script setup>

</script>
<template>
  <div class="div-style">
    <div class="main-container w-3/4 p-10 ">
      <div>
        <img src="/public/assets/nct-white-logo.svg" class="white-logo"/>
      </div>
      <div class="flex items-center flex-col">
          <p class="small-text px-5 py-10">Connectez-vous à votre espace Nutriction santer </p>
        <div class="flex flex-row flex-wrap w-full justify-evenly">
          <router-link to="/login" style="text-decoration: none">
            <div class="account-type flex flex-col items-center mx-2 my-9">
              <div class="card-header flex justify-center items-center">
                <p class="type-text">Professionel <br> de Nutrition</p>
              </div>
              <div>
                <img class="account-icon" style="bottom:0px" src="/public/assets/professional.svg"/>
              </div>
            </div>
          </router-link>
          <router-link to="/login" style="text-decoration: none">
            <div class="account-type flex flex-col items-center mx-2 my-9">
              <div class="card-header flex justify-center items-center">
                <p class="type-text">Patient</p>
              </div>
              <div>
                <img class="account-icon" src="/public/assets/patient.svg"/>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.div-style {
  padding: 60px 50px;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-container {
  background: #238C56;
  border-radius: 20px;
  height: auto;
}
.white-logo {
  max-width: 220px !important;
  height: auto;
}

.image {
  width: 215px;
  height: 255px;
  margin-left: -25px;
}

.image2 {
  width: 155px;
  height: 180px;
  margin-left: -25px;
  margin-top: 20%;
}

.second-div {
  margin-top: -5%;
  display: flex;
  justify-content: center;
}

.third-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.fourth-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.small-text {
  color: white;
  font-family: Rokkitt;
  font-size: 30px;
  font-weight: 700;
}

.header-text {
  color: white;
  font-family: Rokkitt;
  font-size: 40px;
  font-weight: 700;
}

.account-type {
  width: 222px;
  height: 273px;
  background: #FBFBFB;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
  border-radius: 36px;
}

.card-header {
  width: 150px;
  height: 81px;
  background: #FBFBFB;
  border: 3px solid #A2D5AC;
  border-radius: 9px;
  position: relative;
	bottom: 40px;
}

.type-text {
  color: #238C56;
  font-family: 'Rokkitt';
  font-weight: 700;
  font-size: 20px;
}

.account-icon {
  position: relative;
  bottom: 25px;
  filter: drop-shadow(-10px 10px 9px rgb(0 0 0 / 0.4))
}
</style>
