<template>
  <div class="main-body max-sm:px-5">
    <div class="max-sm:w-full w-5/6 flex flex-row flex-wrap max-lg:justify-center" style="background: #ffd7b5">
      <div class="mt-8 ml-5 w-4/6" >
        <p class="text-black-700 text-3xl font-extrabold ">Vous êtes curieux ? Vous avez des questions ? </p>
        <p class="text-xl font-medium ">Réservez votre Rendez-vous, Découverte gratuite !</p>
      </div>
      <div class="box-gratuit my-5">
        <a href="https://appointeo.com/service/3//nutrict" target="_blank">
          <p class="gratuitement">Réservez</p>
          <p class="description-title-gratuit">Gratuitement</p>
        </a>
      </div>
    </div>
    <p class="title-text">Tarifs</p>
    <div class="flex flex-row flex-wrap justify-center mb-5">
      <div v-for="tarif in tarifs" :key="tarif.id" class="product-card my-5 mx-10">
        <div class="card-header"
             :style="[
                    tarif.name == 'Standard' ? { 'background' : 'linear-gradient(262.82deg, #686634 10.96%, #3CBA5C 96.55%)' } : '',
                    tarif.name == 'Premium' ? { 'background' : 'linear-gradient(268.25deg, #64511E 1.04%, #087224 100.91%, #64511E 100.92%)' } : ''
                ]">
          <center><p class="product-title">{{tarif.name}}</p></center>
        </div>
          <div class="price-section">
            <a :href=tarif.link>
              <p class="price">{{tarif.price}}<span class="currency">Є</span></p>
            </a>
          </div>
        <div class="mt-5">
          <p class="description-title pl-1">{{tarif.description.title}}</p>
        </div>

        <div class="scrollbar product-info" style="height: 200px;">
          <ul>
            <li v-for="item in tarif.description.subdescription" class="product-details">
              <div class="flex flex-row items-baseline">
                <img :src='assetPath+"/assets/check-green-icon.svg"' class="check-icon">
                <p class="description pl-1" style="font-weight: bold" >{{item}}</p>
              </div>

            </li>
          </ul>
        </div>
        <p class="description-title pl-1 mt-2">Durée {{tarif.duration}}</p>
        <div class="card-footer ">
            <button class="submit-btn">
                <a :href=tarif.link>Commencer</a>
            </button>
        </div>
      </div>
    </div>

    <p class="title-text ">Formule Forfaitaire</p>
    <p class="text-xl font-bold">(Sur demande)</p>
    <div class="flex flex-row flex-wrap justify-center mb-24 mt-12">
      <div v-for="forfait in forfaits " :key="forfait.id" class="product-card my-5 mx-10">
        <div class="card-header"
          :style="[
                    forfait.name == 'Standard' ? { 'background' : 'linear-gradient(262.82deg, #686634 10.96%, #3CBA5C 96.55%)' } : '',
                    forfait.name == 'Premium' ? { 'background' : 'linear-gradient(268.25deg, #64511E 1.04%, #087224 100.91%, #64511E 100.92%)' } : ''
                ]">
          <center><p class="product-title">Formule {{forfait.duration}} Mois</p></center>
        </div>
        <div class="price-section">
          <p class="price">{{forfait.price}}<span class="currency">Є</span>
        </p>
          <P class="text-green-600 text-xl"><s>{{forfait.old_price}}Є</s></p>
        </div>
        <div class="mt-12">
          <p class="description-title pl-1">La formule du {{forfait.duration}} mois inclut:</p>
        </div>

        <div class="scrollbar product-info">
          <ul>
            <li v-for="description in forfait.description" class="product-details">
              <div class="flex flex-row items-baseline">
                <img :src='assetPath+"/assets/check-green-icon.svg"' class="check-icon">
                <p class="description pl-1" style="font-weight: bold">{{ description }}</p>
              </div>

            </li>
          </ul>
        </div>
        <div class="card-footer ">
            <router-link to="/contact-us" style="text-decoration: none">
                <button class="submit-btn">
                    Contactez-nous
                </button>
            </router-link>
        </div>
      </div>
    </div>
    <div class="max-sm:w-full w-5/6 flex flex-row flex-wrap max-lg:justify-center" style="background: rgba(201, 239, 209, 0.23);">
      <div class="mt-8 ml-5 w-4/6" >
        <p class="text-green-700 text-2xl font-extrabold ">Vous ne souhaitez pas de suivi ? </p>
        <p class="text-xl font-medium ">Bilan nutritionnel complet + mise en place d’un programme alimentaire personnalisé : </p>
      </div>
      <div class="box my-5">
        <a href="https://appointeo.com/service/7//nutrict">
            <p class="price">150 <span style="font-style: normal">Є</span></p>
            <p class="description-title">2H - 2H30min</p>
        </a>
      </div>
    </div>
    <div class="max-sm:w-full w-5/6 flex flex-row mt-8 flex-wrap max-lg:justify-center" style="background: rgba(201, 239, 209, 0.23);">
      <div class="mt-12 ml-5 w-4/6">
        <p class="text-green-700 text-2xl font-extrabold">Ateliers diététiques pour les particuliers ou les professionnels </p>
      </div>
      <div class="box my-5">
          <router-link to="/contact-us" style="text-decoration: none">
              <p class="description-title m-0">Devis & Tarifs sur demande </p>
          </router-link>
      </div>
    </div>
    <div class="w-5/6 mt-8">
      <p class="title-text tracking-wider text-green-600 text-center">Nos tarifs et formules incluent tout ce qu'il vous faut pour un suivi nutritionnel de qualité.</p>
      <img :src='assetPath+"/assets/brown-leaf.png"' style="float: right;">
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductPricing",
  data() {
    return {
      tarifs: [
        {
          id: 0,
          name: "Bilan nutritionnel",
          price: 50,
          duration: "1H30",
          description:
            {
              title: 'Le bilan inclut:',
              subdescription: ['La découverte active;',"L'analyse médicale et technique;","L'analyse nutritionnelle;",'La solution nutritionnelle et pleine santé.', 'Disponibilité permanente (Whatsapp).']
            },
          link: 'https://appointeo.com/service/4//nutrict'
        },
        {
          id: 1,
          name: "Consultation de Suivi",
          price:  30,
          duration: "40 minutes",
          description:
            {
              title: 'La consultation de suivi inclut:',
              subdescription:["L'analyse du carnet alimentaire;",'Le suivi et la mise en place des nouveaux objectifs;','Atelier éducatifs avec des outils santé;','Le coaching pleine santé.', 'Disponibilité permanente (Whatsapp).']
            },
          link: 'https://appointeo.com/service/5//nutrict'
        },
        {
          id: 2,
          name: "Entretien motivationnel",
          duration: "1H",
          price: 35,
          description:
            {
              title: "L'entretien inclut:",
              subdescription: ['Echanger sur les problématique recontrées;','Fixer des objectifs;','Discours-changement et renforcement motivationnel;','Solution-Planifier le changement.' ]
            },
          link: 'https://appointeo.com/service/6//nutrict'
        }
      ],
      forfaits: [
        {
          id: 0,
          name: "Bilan nutritionnel",
          price: 225,
          old_price:260,
          duration: 3,
          description: ['Consultation bilan de nutrition et bien être;','5 consultations de suivi;','Un outil de contact avec votre consultante.', 'Disponibilité permanente (Whatsapp).']
        },
        {
          id: 1,
          name: "Consultation de Suivi",
          price:  335,
          old_price:440,
          duration: 6,
          description: ['13 consultations de suivi;','Un outil de contact avec votre consultante;', 'Un entretien motivationnel offert.', 'Disponibilité permanente (Whatsapp).']
        },
        {
          id: 2,
          name: "Entretien motivationnel",
          duration: 9,
          price: 430,
          old_price:650,
          description: ['19 consultations de suivi;','Un outil de contact avec votre consultante;', '2 entretiens motivationnels offerts.', 'Disponibilité permanente (Whatsapp).']
        }
      ],

    }
  },
  computed: {
    assetPath() {
      return process.env.ASSET_PATH || "";
    }
  }
}
</script>
<style scoped>
@import '../../../sass/components/payement.css';
</style>
